@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700');








.containertable {
    width: 60%;

    border-radius: 20px !important;
	margin-right:auto;
	margin-left:auto;
	display:flex;
	justify-content:center;
	align-items:center;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 60px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}



.table-header {
	display:flex;
	width:100%;
	background:#000;
   
}

.table-row {
	display:flex;
	width:100%;
    background: rgb(247, 246, 246);;
	
}
.table-border{
	border: 0.5px solid rgb(236, 236, 236);
}
.table-border-child{
	border-right: 1px solid rgb(230, 230, 230);
}

.table-data, .header__item {
	flex: 1 1 20%;
	
}

.header__item {
	text-transform:uppercase;
}

.filter__link {
	color:white;
	text-decoration: none;
	position:relative;
	display:inline-block;
	
	
	
}
.heading{
	font-weight: 700;
}

.totalcont{
	font-size: 30px;
	font-weight: 700;
	color: rgb(0, 0, 0);
	
}
.align{
	text-align: left;
	margin-left: 12px;
	font-weight: 600;
}
@media screen and (max-width:700px){
    .containertable{
        width: 100%;
    } 
}