.social{

background: #dcdcdc;
}

.contact{
    color: rgb(0, 0, 0); font-size: 15px;
}

.social{
    padding-top: 25px;
    padding-bottom: 3px;
}
.goog-te-banner-frame.skiptranslate {
    display: none !important;
    } 

#google_translate_element { height: 26px !important; 
    overflow: hidden !important;
    margin-left:35px; 
    margin-top:10px;
}
.footer_container{
 margin-left: 25px;
 margin-right: 25px;
    display: flex;
    justify-content: space-between;
}
.maincontact{
    position: relative;
    top: 2px;
    text-decoration: none;
    color: black;
}
.contactusbutton{
    cursor: pointer;
   
    font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 29px;
position: relative;

padding-right: 10px;
padding-left: 10px;
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 100px;
/* identical to box height */



color: #000000;
}
.socialicons{
    margin-right: 5px;
}
.contactbiz{
    margin-right: 25px;
}
.powered{
    color: rgb(0, 0, 0);
    font-size: 18px;
}
/*<div className='footer_container  '>
      <div className='contactusbutton '><a className='maincontact' >Contact Us</a></div>
       
        
    
    
    
  

           <div > <a className='socialicons'  href='https://www.facebook.com/bizconnectevents/' ><img src={fb} border='0' alt='2809768-facebook-media-online-social-icon-1'/></a>
                       <a className='socialicons'  href= 'https://www.linkedin.com/company/biz-connect-events/' ><img src={lin} border='0' alt='6584510-linkedin-logo-media-social-icon'/></a>

                     <a className='socialicons'  href='https://mobile.twitter.com/BizConnectEven1 ' ><img src={tw} border='0' alt='2809730-media-online-social-twitter-icon'/></a>

                       <a className='socialicons'  href='https://www.instagram.com/bizconnect_global_events/' ><img src={insta} border='0' alt='2809764-instagram-media-online-social-icon'/></a>
                      <a   href='https://www.youtube.com/channel/UCGj27x3_ShNbpu97zMDiYlQ' ><img src={yt} border='0' alt='317714-video-youtube-icon'/></a><pre></pre>
                   
            </div>
            
            </div>
            <div className='text-end text-light '><p className='contactbiz'>contact@bizconnectevents.com</p></div>
           */