

.fontAwesome{ font-family: 'Helvetica', FontAwesome, sans-serif; 
  border: 2px solid rgb(0, 87, 145);
  width: 80%;
  padding: 4px;
}
.topleft{
  display: flex;
}
.logo-cont{

  padding: 2px;
}
.searchit{
  box-shadow: 0px 4px 4px rgba(165, 165, 165, 0.479);
}

.main{
   
  background: #F1F1F1;

}

#heading{
  color: #1E63A4 ;


  font-size: 30px;
  font-weight: 500;
}
#help{ 
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 400 !important;
  color: black !important;
  font-size: 25px;
}
.topbuttons{
  color: white !important;
  background: #1E63A4 !important;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 6.5px !important;
font-weight: 300 !important;
margin-right: 5px;
padding: 6px !important;
text-decoration: none;
font-size: 13px;

}
.topbuttons:hover{
  background: #FF910F !important;
}
#subscribe{
  background: #FF910F !important;
  margin-right: 15px;
}
.bi-pencil-square{
  font-size: 14px !important;
}
.home{
  color: rgb(10, 10, 10) ;
  text-decoration: none;
position: relative;
top: 5px !important;
left: 0px !important;

 font-size: 20px;
}

input{
  padding: 10px;
  border: 1px solid rgb(70, 130, 194);
 width: 100%;
  font-size: 20px;
 border-radius: 25px;
outline:  rgb(70, 130, 194);

}

.navbar{
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.bi-cash-coin{
  font-size: 16px !important;
}
.bi-calculator-fill{
  font-size: 16px !important; 
}

.btn1{
  background: #FF910F !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 6px !important;
  padding: 8px;
  font-weight: 700 !important;
  border:none;
  font-size: 16px !important;
}

.searchbox{
  text-align: center;

}
.homebtn{
  font-size: 30px !important;
color: #1E63A4;
}


h2{
  font-weight: 600;
  color: rgb(255, 255, 255) !important;
}
.faqi{
  text-align: center;
  color: #052747 !important;
  font-weight: 600;
 
}
.expolretopicsrow{
  border-bottom: 1.5px solid #1E63A4 !important;
}

.subscribe{
  margin-right: 20px !important;
}
@media  screen and  ( max-width:777px) {

.topbuttons{
  padding: 5px !important;
  font-size: 15px !important;
margin-right: 1px;

}
.topicons{
  font-size: 11px !important;
}

.submit_request{
  display: none !important;
}
.logo-image{
 height: 50px;
 width: 80px;
 

}
.searchit{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.logo-cont{
  padding: 0px;
  margin-left: 0px;
}

  
}