
.cardSelection {
  background-color: white;
  box-shadow: 0px 0px 20px 14px #a4b7e170;
  width: 280px;
  height: 400px;
  border-radius: 6px;
  padding: 16px 4px;
  border: 2px solid transparent;
  position: relative;
}
.webinar {
  display: flex;
  flex-direction: column;
}
.cs_image {
  width: 260px;
  display: block;
  margin: auto;
  border-radius: 6px;
  border: 2px solid lightgrey;
}
.cs_title {
  color: #281ca6;
  margin: 10px;
}

.cs_date {
  margin: 5px 10px 5px 10px;
  font-size: x-small;
  font-weight: 500;
}

.cs_info {
  font-size: 12px;
  margin: 10px;
  color: #6d6d6d;
  letter-spacing: 0.5px;
}

.cs_info_page {
  border: none;
  font-weight: bold;
  font-size: smaller;
  background-color: transparent;
  outline: none;
}
.cs_info_page:hover {
  text-decoration: underline;
}

.cs_pricing_page {
  background-color: #eaeaeab8;
  color: #281ca6;
  border: 2px solid transparent;
  font-weight: bold;
  border-radius: 5px;
  padding: 2px 6px;
  transition: 0.2s all ease-in-out;
}

.cs_pricing_page:hover {
  border: 2px solid #281ca6;
}


.billcontainer {
  display: flex;
  justify-content: center;
}
.billcontainer_span {
  font-size: 24px;
  text-align: center;
  width: 15px !important;
}
.currency {
  padding-right: 8px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
.colchild {
  padding-top: 20px;
}
.childcontent,
.childcontentlast {
  color: #4b4b4b;
  letter-spacing: 1.5px;
  margin-top: 1rem;
}
@media screen and (max-width: 576px) {
  .colchild {
      padding-top: 35px;
  }
}
.childheading {
  font-size: 23.7px;
}

.verticalCenter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 6px solid #1e63a4;
  transition: 0.3s all ease-in-out;
  position: relative;
  align-items: center;
  overflow: hidden;
}

.Hover_turn {
  display: grid;
  place-items: center;
  border-top: 6px solid #1e63a4;
  border-top-left-radius: 20px;
  transition: 0.3s all ease-in-out;
}
.verticalCenter:hover,
.Hover_turn:hover {
  border-top: 6px solid #f08d16;
}

@media screen and (max-width: 998px) {
  .verticalCenter,
  .Hover_turn {
      border-top: unset;
  }
}

.price_container_new {
  border-radius: 20px;
}
.childprice {
  margin-bottom: 4px;
}

.most_popular {
  position: absolute;
  transform: translate(43%, 23px) rotate(45deg);
  width: 98%;
  padding: 0px;

  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: 2px;
  font-size: small;
  font-weight: bold;
  box-shadow: 2px 1px 4px #9f9f9f;
  background-color: #1e63a4;
  color: white;
}

.most_popular.subscription {
  transform: unset;
}

@media screen and (min-width: 992px) {
  .most_popular {
      position: absolute;
      transform: translate(80px, 23px) rotate(45deg);
      width: 98%;
      padding: 0px;

      text-transform: uppercase;
      overflow: hidden;
      letter-spacing: 2px;
      font-size: small;
      font-weight: bold;
      box-shadow: 2px 1px 4px #9f9f9f;
      background-color: #1e63a4;
      color: white;
  }
  .most_popular.subscription {
      transform: translate(80px, 1px) rotate(46deg);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .most_popular {
      transform: translate(103px, 23px) rotate(45deg);
  }
  .most_popular.subscription {
      transform: translate(89px, 4px) rotate(46deg);
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .most_popular {
      transform: translate(61px, 23px) rotate(45deg);
  }
  .most_popular.subscription {
      transform: translate(66px, 1px) rotate(46deg);
  }
}



@media screen and (min-width: 768px) and (max-width: 991.9999px) {
  
  .most_popular.subscription {
      transform: translate(326px, 5px) rotate(46deg);
  }
  .subscription_container div.span , .webinar_container div.span {
      right: 41.5% !important;
  }
  
}

@media screen and  (min-width:991.9999px)  and  (max-width: 991.9999px) {
  .subscription_container div.span ,.webinar_container div.span{
      right: 21.5% !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 767.9999px) {
  .most_popular.subscription {
      transform: translate(245px, 5px) rotate(46deg);
  }
  .subscription_container div.span,.webinar_container div.span {
      right: 37% !important;
  }
}
@media screen and (min-width: 541px) and (max-width: 576px) {
  .most_popular.subscription {
      transform: translate(245px, 5px) rotate(46deg);
  }
  .subscription_container div.span , .webinar_container div.span {
      right: 38.3% !important;
  }
}
@media screen and (min-width: 492px) and (max-width: 541px) {
  .most_popular.subscription {
      transform: translate(215px, 20px) rotate(46deg);
  }
  .subscription_container div.span , .webinar_container div.span {
      right: 37.6% !important;
  }
}

@media screen and (min-width: 450px) and (max-width: 492px) {
  .most_popular.subscription {
      transform: translate(189px, 22px) rotate(46deg);
  }
  .subscription_container div.span  , .webinar_container div.span{
      right: 35.6% !important;
  }
}
@media screen and (min-width: 400px) and (max-width: 450px) {
  .most_popular.subscription {
      transform: translate(171px, 18px) rotate(46deg);
  }
  .subscription_container div.span ,.webinar_container div.span {
      right: 34.3% !important;
  }
}

@media screen and (min-width: 350px) and (max-width: 400px) {
  .most_popular.subscription {
      transform: translate(144px, 16px) rotate(46deg);
  }
  .subscription_container div.span ,.webinar_container div.span {
      right: 32% !important;
  }
}

@media screen and (min-width: 300px) and (max-width: 350px) {
  .most_popular.subscription {
      transform: translate(125px, 16px) rotate(46deg);
  }
  .subscription_container div.span , .webinar_container div.span {
      right: 28% !important;
  }
}

.most_popular_container {
  transform: scale(1.07);
  overflow: hidden;
  background: white;
  box-shadow: 0px 0px 10px #afa7a7b7;
  cursor: pointer;
  background: url(https://media.istockphoto.com/vectors/abstract-blue-and-orange-wave-vector-background-vector-id1142714085?b=1&k=20&m=1142714085&s=612x612&w=0&h=p-k80XPBHWxyW3GoipWrxh69VdPetWqmPzmXdiubipQ=);
  background: rgb(255, 0, 240);
  background: linear-gradient(
      32deg,
      rgb(222 239 255) 0%,
      rgb(255 255 255) 100%
  );
  transition: all 0.3s ease;
  z-index: 1;
}
.most_popular_container:hover {
  transform: scale(1);
  background: linear-gradient(
      32deg,
      rgb(255 255 255) 0%,
      rgb(255 255 255) 100%
  );
  box-shadow: unset;
  transition: all 0.3s ease;
}

.show_more_less {
  border: none;
  padding: 4px;
  background-color: transparent;
  color: #1e63a4;
  display: block;
  margin: auto;
  border: 1px solid #1e63a4;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 8px auto;
}
.show_more_less:hover {
  background-color: #2f74b5;
  color: white;
}

@media screen and (max-width: 992px) {
  .most_popular_container {
      transform: scale(1);
      box-shadow: unset;
      border-radius: 4px;
  }
}

.span:hover {
  background-color: rgb(30 99 164);
  color: white !important;
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}
.spanlast:hover {
  background-color: rgba(245, 135, 50, 255);
  color: white !important;
  cursor: pointer;
}
.no_of_sing {
  text-align: center;
  font-weight: 600;
  padding: 10px;
  color: rgb(73, 73, 73);
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-md-12 {
  color: rgb(50, 50, 50);
}

.section {
  background-color: rgba(250, 250, 250, 255);
  padding: 10px;
  font-size: 20px;
}

div.col.no_of_sing.border-end.border-bottom .fa-info-circle {
  cursor: pointer;
  font-size: medium !important;
}

.bi-check {
  font-size: 22px !important;
}
.bi-x-lg {
  font-size: 20px !important;
  font-weight: 800 !important;
}
.bi-cup-straw {
  font-size: 30px !important;
  color: orangered;
}

.cost_section {
  background-color: rgb(231, 243, 253);
}

.coffe {
  text-align: center;
  padding: 15px;
  font-weight: 700;
  width: 130%;
}

@media screen and (max-width: 992px) {
  .heading {
      font-size: 12px;
      padding: 8px;
      text-align: center;
      font-weight: 900;
  }
  .coffe {
      border: 2px soild black;
  }
  .pricerow {
      display: none;
  }
}
@media screen and (min-width: 993px) and (max-width: 4000px) {
  .heading {
      display: none;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .responsive {
      padding-top: 24px;
  }
}
@media screen and (min-width: 990px) and (max-width: 1200px) {
  .span {
      font-size: 12px;
  }
  .spanlast {
      font-size: 12px;
  }
}

@media screen and (max-width: 770px) {
  .no_of_sing {
      font-size: 8px;
      font-weight: 700;
  }
  .bi-check {
      font-size: 15px !important;
  }
}

div.col.no_of_sing.border-end.border-bottom:hover {
  background-color: #dddddd85;
}

div.span {
  border: 1.5px solid rgb(30 99 164 / 75%);
  border-radius: 5px;
  font-weight: 500;
  color: rgb(30 99 164 / 75%);
  padding: 6px;
  padding-left: 16px;
  padding-right: 16px;
  width: max-content;
  text-align: center;
  display: block;
  font-weight: normal;
  position: absolute !important;

  margin: auto;
}

.subscription_container div.span {
  right: 20%;
}

.webinar_container div.span {
  right: 25%;
}
@media screen and (max-width: 576px) {
  .webinar_container div.span {
    right: 30%;
  }

  .subscription_container div.span {
    right: 30%;
  }
}

div.spanLast:hover {
  background-color: rgba(245, 135, 50, 255);
}

div.hide div.row div:first-child {
  text-align: start;
  align-items: center;
  /* display: unset; */
  justify-content: space-between;
}

.Note {
  font-size: x-small;
  margin: 4px;
  display: inline-block;
  align-items: center;
  flex-direction: column;
}

.Note p {
  background-color: rgba(255, 93, 93, 0.173);
  color: red;
  border-radius: 4px;
  padding: 2px 8px;
  display: block;
  width: max-content;
  margin: 0px 2px 4px 0px;
}

.clickOnshow_more .row:nth-child(even) {
  background-color: #eeeeee;
}

.col.no_of_sing.border-end.border-bottom .iconify {
  font-size: 22.5px !important;
}

.billcontainer {
    display: flex;
    justify-content: center;
}
.billcontainer_span {
    font-size: 24px;
    text-align: center;
    width: 15px !important;
}
.currency {
    padding-right: 8px;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
}
.colchild {
    padding-top: 20px;
}
.childcontent,
.childcontentlast {
    color: #4b4b4b;
    letter-spacing: 1.5px;
    margin-top: 1rem;
}
@media screen and (max-width: 576px) {
    .colchild {
        padding-top: 35px;
    }
}
.childheading {
    font-size: 23.7px;
}

.verticalCenter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 6px solid #1e63a4;
    transition: 0.3s all ease-in-out;
    position: relative;
    align-items: center;
    overflow: hidden;
}

.Hover_turn {
    display: grid;
    place-items: center;
    border-top: 6px solid #1e63a4;
    border-top-left-radius: 20px;
    transition: 0.3s all ease-in-out;
}
.verticalCenter:hover,
.Hover_turn:hover {
    border-top: 6px solid #f08d16;
}

@media screen and (max-width: 998px) {
    .verticalCenter,
    .Hover_turn {
        border-top: unset;
    }
}

.price_container_new {
    border-radius: 20px;
}
.childprice {
    margin-bottom: 4px;
}

.most_popular {
    position: absolute;
    transform: translate(43%, 23px) rotate(45deg);
    width: 98%;
    padding: 0px;

    text-transform: uppercase;
    overflow: hidden;
    letter-spacing: 2px;
    font-size: small;
    font-weight: bold;
    box-shadow: 2px 1px 4px #9f9f9f;
    background-color: #1e63a4;
    color: white;
}

.most_popular.subscription {
    transform: unset;
}

@media screen and (min-width: 992px) {
    .most_popular {
        position: absolute;
        transform: translate(80px, 23px) rotate(45deg);
        width: 98%;
        padding: 0px;

        text-transform: uppercase;
        overflow: hidden;
        letter-spacing: 2px;
        font-size: small;
        font-weight: bold;
        box-shadow: 2px 1px 4px #9f9f9f;
        background-color: #1e63a4;
        color: white;
    }
    .most_popular.subscription {
        transform: translate(80px, 1px) rotate(46deg);
    }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .most_popular {
        transform: translate(103px, 23px) rotate(45deg);
    }
    .most_popular.subscription {
        transform: translate(89px, 4px) rotate(46deg);
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .most_popular {
        transform: translate(61px, 23px) rotate(45deg);
    }
    .most_popular.subscription {
        transform: translate(66px, 1px) rotate(46deg);
    }
}



@media screen and (min-width: 768px) and (max-width: 991.9999px) {
    
    .most_popular.subscription {
        transform: translate(326px, 5px) rotate(46deg);
    }
    .subscription_container div.span , .webinar_container div.span {
        right: 41.5% !important;
    }
    
}

@media screen and  (min-width:991.9999px)  and  (max-width: 991.9999px) {
    .subscription_container div.span ,.webinar_container div.span{
        right: 21.5% !important;
    }
}
@media screen and (min-width: 576px) and (max-width: 767.9999px) {
    .most_popular.subscription {
        transform: translate(245px, 5px) rotate(46deg);
    }
    .subscription_container div.span,.webinar_container div.span {
        right: 37% !important;
    }
}
@media screen and (min-width: 541px) and (max-width: 576px) {
    .most_popular.subscription {
        transform: translate(245px, 5px) rotate(46deg);
    }
    .subscription_container div.span , .webinar_container div.span {
        right: 38.3% !important;
    }
}
@media screen and (min-width: 492px) and (max-width: 541px) {
    .most_popular.subscription {
        transform: translate(215px, 20px) rotate(46deg);
    }
    .subscription_container div.span , .webinar_container div.span {
        right: 37.6% !important;
    }
}

@media screen and (min-width: 450px) and (max-width: 492px) {
    .most_popular.subscription {
        transform: translate(189px, 22px) rotate(46deg);
    }
    .subscription_container div.span  , .webinar_container div.span{
        right: 35.6% !important;
    }
}
@media screen and (min-width: 400px) and (max-width: 450px) {
    .most_popular.subscription {
        transform: translate(171px, 18px) rotate(46deg);
    }
    .subscription_container div.span ,.webinar_container div.span {
        right: 34.3% !important;
    }
}

@media screen and (min-width: 350px) and (max-width: 400px) {
    .most_popular.subscription {
        transform: translate(144px, 16px) rotate(46deg);
    }
    .subscription_container div.span ,.webinar_container div.span {
        right: 32% !important;
    }
}

@media screen and (min-width: 300px) and (max-width: 350px) {
    .most_popular.subscription {
        transform: translate(125px, 16px) rotate(46deg);
    }
    .subscription_container div.span , .webinar_container div.span {
        right: 28% !important;
    }
}

.most_popular_container {
    transform: scale(1.07);
    overflow: hidden;
    background: white;
    box-shadow: 0px 0px 10px #afa7a7b7;
    cursor: pointer;
    background: url(https://media.istockphoto.com/vectors/abstract-blue-and-orange-wave-vector-background-vector-id1142714085?b=1&k=20&m=1142714085&s=612x612&w=0&h=p-k80XPBHWxyW3GoipWrxh69VdPetWqmPzmXdiubipQ=);
    background: rgb(255, 0, 240);
    background: linear-gradient(
        32deg,
        rgb(222 239 255) 0%,
        rgb(255 255 255) 100%
    );
    transition: all 0.3s ease;
    z-index: 1;
}
.most_popular_container:hover {
    transform: scale(1);
    background: linear-gradient(
        32deg,
        rgb(255 255 255) 0%,
        rgb(255 255 255) 100%
    );
    box-shadow: unset;
    transition: all 0.3s ease;
}

.show_more_less {
    border: none;
    padding: 4px;
    background-color: transparent;
    color: #1e63a4;
    display: block;
    margin: auto;
    border: 1px solid #1e63a4;
    padding: 4px 8px;
    border-radius: 4px;
    margin: 8px auto;
}
.show_more_less:hover {
    background-color: #2f74b5;
    color: white;
}

@media screen and (max-width: 992px) {
    .most_popular_container {
        transform: scale(1);
        box-shadow: unset;
        border-radius: 4px;
    }
}

.span:hover {
    background-color: rgb(30 99 164);
    color: white !important;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}
.spanlast:hover {
    background-color: rgba(245, 135, 50, 255);
    color: white !important;
    cursor: pointer;
}
.no_of_sing {
    text-align: center;
    font-weight: 600;
    padding: 10px;
    color: rgb(73, 73, 73);
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.col-md-12 {
    color: rgb(50, 50, 50);
}

.section {
    background-color: rgba(250, 250, 250, 255);
    padding: 10px;
    font-size: 20px;
}

div.col.no_of_sing.border-end.border-bottom .fa-info-circle {
    cursor: pointer;
    font-size: medium !important;
}

.bi-check {
    font-size: 22px !important;
}
.bi-x-lg {
    font-size: 20px !important;
    font-weight: 800 !important;
}
.bi-cup-straw {
    font-size: 30px !important;
    color: orangered;
}

.cost_section {
    background-color: rgb(231, 243, 253);
}

.coffe {
    text-align: center;
    padding: 15px;
    font-weight: 700;
    width: 130%;
}

@media screen and (max-width: 992px) {
    .heading {
        font-size: 12px;
        padding: 8px;
        text-align: center;
        font-weight: 900;
    }
    .coffe {
        border: 2px soild black;
    }
    .pricerow {
        display: none;
    }
}
@media screen and (min-width: 993px) and (max-width: 4000px) {
    .heading {
        display: none;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .responsive {
        padding-top: 24px;
    }
}
@media screen and (min-width: 990px) and (max-width: 1200px) {
    .span {
        font-size: 12px;
    }
    .spanlast {
        font-size: 12px;
    }
}

@media screen and (max-width: 770px) {
    .no_of_sing {
        font-size: 8px;
        font-weight: 700;
    }
    .bi-check {
        font-size: 15px !important;
    }
}

div.col.no_of_sing.border-end.border-bottom:hover {
    background-color: #dddddd85;
}

div.span {
    border: 1.5px solid rgb(30 99 164 / 75%);
    border-radius: 5px;
    font-weight: 500;
    color: rgb(30 99 164 / 75%);
    padding: 6px;
    padding-left: 16px;
    padding-right: 16px;
    width: max-content;
    text-align: center;
    display: block;
    font-weight: normal;
    position: absolute !important;

    margin: auto;
}

.subscription_container div.span {
    right: 20%;
}
.webinar_container div.span {
    right: 25%;
}

div.spanLast:hover {
    background-color: rgba(245, 135, 50, 255);
}

div.hide div.row div:first-child {
    text-align: start;
    align-items: center;
    /* display: unset; */
    justify-content: space-between;
}

.Note {
    font-size: x-small;
    margin: 4px;
    display: inline-block;
    align-items: center;
    flex-direction: column;
}

.Note p {
    background-color: rgba(255, 93, 93, 0.173);
    color: red;
    border-radius: 4px;
    padding: 2px 8px;
    display: block;
    width: max-content;
    margin: 0px 2px 4px 0px;
}

.clickOnshow_more .row:nth-child(even) {
    background-color: #eeeeee;
}

.col.no_of_sing.border-end.border-bottom .iconify {
    font-size: 22.5px !important;
}

#premium-plan-price_css,
#express-plan-price_css,
#free-plan-price_css {
    margin-bottom: 0;
    line-height: 30px;
}

.starts_at,
.wild_card,
.duration {
    color: gray;
}
.dollar {
    font-weight: bold;
    font-size: smaller;
    position: absolute;

    transform: translate(-8px, -12px);
}
.main_price {
    font-weight: bold;
    font-size: 60px;
}
.wild_card {
    position: absolute;
    transform: translate(2px, -12px);
}
.duration {
    text-transform: capitalize;
}

.nagative_margin {
    margin-top: -7px;
}

.nagative_margin span {
    color: gray;
}

.updrage_now {
  text-decoration: none;
  color: inherit;
}

.updrage_now:hover {
  color: inherit;
}
