.cont_container {
  padding-top: 5px;
  padding-right: 5px;
  border-radius: 10px;
  border: 0.5px solid #ececec;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  transition-duration: 0.5s;
  padding: 12px;
  height: 100%;
}

.contmax {
  margin-left: 50px;
  margin-right: 50px !important;
}
.cont_container:hover {
  box-shadow: rgb(132 129 129 / 15%) 1px 1px 9px 4px;
}
@font-face {
  font-family: heading;
  src: url(fonts/Slabo13px-Regular.ttf);
}

.writebutton {
  background: #1e63a4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-size: 20px;
  border: none;
  padding: 8px;

  color: white;
  text-decoration: none;
}
.writebutton:hover {
  color: white;
  background: #ff910f;
  box-shadow: none;
}
.sidecontent {
  text-align: center;
}
.fg {
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
}
.bi-person-fill {
  font-size: 15px !important;
}
.bi-book {
  font-size: 55px !important;
  color: gray !important;
}
.book {
  text-align: end !important;
}

.topics {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  position: relative;
  top: 10px;
}
.descp {
  padding-top: 10px;
  position: relative;
  top: 10px;
  font-size: 15px;
}
.explore {
  border-right: 1.5px solid #1e63a4;
  text-align: end;
}
.contactcontainer {
  box-shadow: 1px 1px 5px 3px #dadada;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 10px;
}
.bookimage {
  position: relative;
  bottom: 8px;
  right: 2px;
}
.contheading {
  text-decoration: none !important;
  color: rgb(0, 0, 0);
  font-family: revert;
}
.contdetails {
  font-size: 12px;
  text-decoration: none !important;
  color: rgb(23, 23, 23);
}
@media screen and (max-width: 777px) {
  .book {
    text-align: start !important;
  }
  .write_image {
    width: 300px;
  }
  .cont_container {
    margin-bottom: 10px;
  }
  .content_space {
    display: none;
  }
  .contmax {
    margin: 0px;
    margin-right: 0px !important;
  }
  .explore {
    text-align: center;
  }
  .book {
    text-align: center;
    justify-content: center;
  }
}

/* dhruv new card styles */
.card_image {
  max-width: 50px;
}
