.searchbox{
    background-color: #F1F1F1;
}
.question{
    font-size: 20px;
    color: #383838;
    font-weight: 700;
}
.answer{
    font-size: 13px;
    color: rgb(69, 69, 69);
}
.cato{
    margin-left: 15px;
    color: rgb(54, 54, 54);
    padding: 4px;
 
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}
.cato:hover{
    border-left: 3px solid #FF910F ;
    color: black;
    font-weight: 700;
    
}
.cato:active{
    border-left: 3px solid #FF910F ;
    color: black;
    font-weight: 700;
}