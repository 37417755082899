.categories {
  font-weight: 500;
  font-size: 17px;
  color: rgb(20, 20, 20);
}
.contentcategories {
  margin-left: 40px;
  color: rgb(54, 54, 54);
  padding: 4px;

  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.vbu {
  padding: 10px;
  color: #ff910f;
  font-weight: 500;
  font-size: 20px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid #ff910f;
}
.vbu:hover {
  background-color: #ff910f;
  color: white;
}
.lines_ {
  list-style-type: none;
  font-size: 13px;
  color: rgb(84, 84, 84);
}
.contentcategories:hover {
  border-left: 3px solid #ff910f;
  color: black;
  font-weight: 700;
}
.contentcategories:active {
  border-left: 3px solid #ff910f;
  color: black;
  font-weight: 700;
}
.descripton {
  border: 0.5px solid rgb(221, 221, 221);
  border-radius: 20px;
}
.lines {
  font-size: 13px;
  font-weight: 500;
  color: rgb(84, 84, 84);
  font-family: Arial, Helvetica, sans-serif !important;
}

.temp {
  list-style-type: none;
  font-size: 13px;
  font-weight: 500;
  color: rgb(84, 84, 84);
  font-family: Arial, Helvetica, sans-serif !important;
}
.categoriescont {
  border: 0.5px solid rgb(221, 221, 221);
  border-radius: 8px;
}
#bold {
  font-weight: 600;
}
.tableheader {
  background-color: rgb(64, 64, 64) !important;
  color: white;
}
.tablecontent {
  background-color: rgb(255, 250, 249) !important;
  color: rgb(5, 5, 5);
}

@media screen and (max-width: 810px) {
  #index {
    position: relative;
  }
}

.contentBorder{
    border-radius: 8px;
}