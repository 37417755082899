.features{
    text-align: center;
   
    border-bottom: 0.5px solid rgb(248, 248, 248);

font-size: 10px;
}
.alternate{
    background-color: #fffef9;
}

#qotation{
    margin-left: 100px;
   display: none;
  
}
.itemmargin{
    margin-right: 80px;
}
.tophead{
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
/*<span className='words ' >({totalwords.toUpperCase()}  DOLLARS )</span>*/
.total2{
    font-size: 13px;
    color: rgb(0, 60, 255);
    font-weight: 700;
}
.totalbottom{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: rgb(0, 60, 255);
    font-weight: 600;
}
.costtable{
    margin-left: 5px;
}

.head{text-align: center;
    color: black !important;
}
.lastline{
    font-size: 9px;
    color: rgb(46, 46, 46);
}
#qotform{
    margin-bottom: 20px;
}
.logo{
    text-align: start;
    margin:10px;
}
.words{
    font-size: 13px !important;
}
#qotform{
    display: none;
}
.top{
    text-align: center;
  background-color: rgb(101, 101, 101);
  color: white;
    padding: 1px;
   
    font-size: 13px;
    font-weight: 700;
}

.adress{

    font-size: 12px;
}
#qotation{
width: 740px;
}
.topsection{
    display: flex !important;
    justify-content: space-between;
}
.datedetail{
    font-size: 13px;
}
.imgdownline{
margin: 0px;
    border-bottom: 4px solid black;
}

.qotationbutton{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: #FF910F;
    background: #FFFFFF;
border: 3px solid #FF910F;
box-sizing: border-box;
border-radius: 12px;
padding: 10px;

}
.qotationbuttonmini{
    
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #FF910F;
    background: #FFFFFF;
border: 3px solid #FF910F;
box-sizing: border-box;
border-radius: 8px;
padding: 5px;
background-color: orangered;
    color: white;
}

.qotationbutton:hover{
    background-color: orangered;
    color: white;
}
#spiner{
    text-align: center;
    display: none;
}
.bf{
    text-align: end;
    padding-top: 20px;
}
#qotform{
    box-shadow: 1px 1px 15px 3px #e7e7e7;
    padding: 20px;
    border-radius: 10px;
}
/*@media screen and (max-width:800px){

   
}*/