
.modalBackground {
    width: 100vw;
    height: 100vh;
    z-index: 1;
    position: fixed;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(74, 74, 74, 0.413);
  }
  
  .modalContainer {
    width: 700px;
  
    border-radius: 20px;
    background-color: rgb(253, 253, 253);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   
    padding: 15px;
    
  }

#video{
    width: 200px;
}
  
  .form-label{
    font-size: 15px;
    font-weight: 700;
  }
  .modalContainer .title {
    display: inline-block;
    text-align: center;
  
  }
  input::placeholder{
    color: rgb(91, 91, 91) !important;
    
  
  }
  .errormessage{
    font-weight: 600;
    font-size: 15px;
  }
  .form-control{
    background-color: rgb(239, 246, 255);
  }
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
    
  }
  .CloseBtn{
    font-size: 65px;
    font-weight: 700;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 15px;
    cursor: pointer;
  }
  
  .modalContainer .body {
  
  
    font-size:20px;
  
  }
  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    padding: 5px;
    margin: 10px;
    border: none;
    background-color: rgb(17, 17, 17);
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }