

.fonts{
  margin-left: 2px;
 
}
.conat{
  margin: 50px;
}
#sub{
  text-align: start;
}
.btn {
  letter-spacing: 1px;
}
.pricing {
  cursor: pointer;
}
.border-bottom {
  border-bottom: 3px solid #c4c4c4 !important;
}
.submit-request {
  font-size: 30px;
}
.formcontent{
  font-size: 13px;
}
.form {
  background-color: #f0f0f0;
  padding: 28px;
  border-radius: 12px;
}
.form-control2 {
  margin-bottom: 12px;
  padding: 2px !important;
}
.backgroundcolor {
  color: #1c1c1cd2;
}
.img-1 {
  border-radius: 10px;
  user-select: none;
}
.form-out-side{
  width: 50%;
}

@media screen and (min-width: 570px) and (max-width: 1400px) {
  .content-of-form {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .pice{
    display: none !important;
  }
}
.content-of-form {
  margin-bottom: 50px !important;
}

.form-out-side {
  padding: 10px !important;
}



@media screen and (max-width: 600px) {
.pice{
  display: none !important;
}
.form-out-side{
  width: 100%;
}
.conat{
  margin: 0px;
}
}